<template>
	<!-- 订单列表 -->
	<div class="orderList">
		<!-- 导出模板 -->
		<div :id="'export-template' + index" v-for="(item, index) in selectedData" :key="index"
			style="position: fixed;top: -999999px;">
			<div id="template-title">
				<div style="line-height: 50px;font-size: 20px;" align=center>
					<strong>
						<font>{{ supplier_info.supplier_name }}销售单</font>
					</strong>
				</div>
				<table border=0 cellSpacing=0 cellPadding=0 width="90%" style="line-height: 20px;font-size: 14px;">
					<tbody>
						<tr>
							<td>
								<font>下单时间：<span>{{ item.pay_time }}</span></font>
							</td>
							<td>
								<font>打印时间：<span>{{ currentDateTime }}</span></font>
							</td>
							<td>
								<font>客户：<span>{{ item.contacts }}</span></font>
							</td>
						</tr>
						<tr>
							<td>
								<font>地址：<span>{{ item.address }}</span></font>
							</td>
							<td>
								<font>电话：<span>{{ item.phone }}</span></font>
							</td>
							<td>
								<font>仓库：{{ item.warehouse_name }}</font>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div id="template-table">
				<table border=1 cellSpacing=0 cellPadding=1 width="92%" style="font-size: 16px;border-collapse:collapse"
					bordercolor="#333333">
					<thead>
						<tr>
							<td width="5%"></td>
							<td width="30%">
								<div align=center><b>商品名称</b></div>
							</td>
							<td width="25%">
								<div align=center><b>商品简介</b></div>
							</td>
							<td width="10%">
								<div align=center><b>数量</b></div>
							</td>
							<td width="15%">
								<div align=center><b>单价</b></div>
							</td>
							<td width="15%">
								<div align=center><b>小计</b></div>
							</td>
						</tr>
					</thead>
					<tbody>

						<tr v-for="(itemChil, indexChil) in item.order_goods" :key="indexChil">
							<td align=center>{{ indexChil + 1 }}</td>
							<td align=center>{{ itemChil.goods_name }}</td>
							<td align=center>{{ itemChil.goods_intro }}</td>
							<td align=center>{{ itemChil.count }}</td>
							<td align=center>{{ itemChil.price }}</td>
							<td align=center>{{ (itemChil.count * itemChil.price).toFixed(2) }}</td>
						</tr>

					</tbody>
					<tfoot>
						<tr>
							<td align="center" colspan="2">
								<span tdata='pageNO'>第##</span>
								<span> / </span>
								<span tdata='pageCount'>##页</span>
							</td>
							<td align="center" colspan="2">
								总计：<span>{{ item.amount }}元</span>
							</td>
							<td align="center">
								本页小计:
							</td>
							<td align="center" tdata="subSum" format="#,##0.00">
								###元
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<!-- <div class="l-item">
                    <div class="item-label">订单类型:</div>
                    <div class="item-input">
                        <el-select v-model="searchForm.order_type" placeholder="请选择">
                            <el-option v-for="item in orderTypeOptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div> -->
				<div class="l-item">
					<div class="item-label">订单号:</div>
					<div class="item-input">
						<el-input v-model="searchForm.ordernumber" placeholder="请输入订单号" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系人:</div>
					<div class="item-input">
						<el-input v-model="searchForm.contacts" placeholder="请输入联系人" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系电话:</div>
					<div class="item-input">
						<el-input v-model="searchForm.phone" placeholder="请输入联系电话" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择">
							<el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">包含商品:</div>
					<div class="item-input">
						<el-input v-model="searchForm.goods_name" placeholder="请输入包含商品的名称关键字" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">提货方式:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
							<el-option v-for="item in deliveryTypeList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">支付方式:</div>
					<div class="item-input">
						<el-select v-model="searchForm.type" placeholder="请选择支付方式" style="width: 100%;">
							<el-option v-for="item in payTypeList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-select v-model="pageType" placeholder="打印纸" @change="selectPageType" style="margin-right: 10px;">
					<el-option v-for="item in pageTypeList" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
				<el-button type="success" @click="handlePrint" :disabled="exportDisabled">批量打印</el-button>
				<el-button type="success" @click="handleExport">导出</el-button>
				<el-button type="warning" @click="writeOff" :disabled="writeOffDisabled">批量核销</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<el-table :data="tableData" style="width: 100%" border @select="handleParentSelect"
				@select-all="handleParentSelectAll" @selection-change="handleParentSelectionChange" ref="parentTable"
				@expand-change="handleParentExpandChange" :header-cell-style="{
					background: '#f5f7fa',
					fontWeight: 'bold',
					color: '#303133'
				}" v-loading="loading">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.order_goods" :ref="'childTable' + props.row.order_id"
							@selection-change="handleChildSelectionChange($event, props.row)" :header-cell-style="{
								background: '#f5f7fa',
								fontWeight: 'bold',
								color: '#303133'
							}" style="width: 100%;border:1px solid #e6a23c">
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column prop="goods_name" label="商品名称" align="center">
								<template slot-scope="scope">
									<span
										v-if="searchForm.goods_name && scope.row.goods_name.indexOf(searchForm.goods_name) >= 0"
										style="color:#5500ff;font-weight: 600;">{{ scope.row.goods_name }}</span>
									<span v-else>{{ scope.row.goods_name }}</span>
								</template>
							</el-table-column>
							<el-table-column label="商品主图" align="center">
								<template slot-scope="scope">
									<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
										:preview-src-list="[scope.row.goods_pic]">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="cost" label="成本价" align="center"></el-table-column>
							<el-table-column prop="cost_amount" label="成本金额" align="center"></el-table-column>
							<el-table-column prop="price" label="订单价" align="center"></el-table-column>
							<el-table-column prop="count" label="数量" align="center"></el-table-column>
							<el-table-column prop="amount" label="订单金额" align="center"></el-table-column>
							<el-table-column label="商品状态" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.status == 1">已支付</span>
									<span v-if="scope.row.status == 1 && scope.row.has_service == 1">已支付(有售后)</span>
									<span v-if="scope.row.status == 2">未发货</span>
									<span v-if="scope.row.status == 3">已到站</span>
									<span v-if="scope.row.status == 4" style="color: #1B9D6E;">已签收</span>
									<span v-if="scope.row.status == 5" style="color: red;">已退款</span>
								</template>
							</el-table-column>
							<el-table-column label="售后详情" prop="service_info" align="center"></el-table-column>
							<el-table-column label="核销状态" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.check_status == 1">未核销</span>
									<span v-if="scope.row.check_status == 2">核销成功</span>
								</template>
							</el-table-column>
							<el-table-column prop="check_time" label="核销时间" align="center"></el-table-column>
							<el-table-column prop="refund_count" label="退款数量" align="center"></el-table-column>
							<el-table-column prop="arrived_date" label="配送日期" align="center">
								<template slot-scope="scope">
									<span style="font-weight:bold">{{ scope.row.arrived_date }}</span>
								</template>
							</el-table-column>
							<el-table-column label="操作" align="center" width="260">
								<template slot-scope="scope">
									<el-button type="primary" size="mini"
										v-if="scope.row.check_status == 2 && scope.row.has_service == 0 && props.row.type == 1 || props.row.type == 2"
										@click="afterSalesDialog = true, afterSalesItem = scope.row">主动售后</el-button>
									<el-button type="danger" size="mini" v-if="scope.row.status == 1"
										@click="refundFn(props.row.order_id, scope.row)">退款</el-button>
									<el-button type="warning" size="mini"
										v-if="scope.row.status == 1 && scope.row.order_type !== 2"
										@click="writeOff(scope.row)">核销</el-button>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55">
					<template slot-scope="{ row }">
						<el-checkbox :indeterminate="row.indeterminate" @change="toggleParentSelection(row, $event)"
							v-model="row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column label="打印时间" prop="warehouse_name" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.print ? scope.row.print.last_print_time : '暂无打印' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="所属仓库" prop="warehouse_name" align="center"></el-table-column>
				<el-table-column label="订单号" prop="ordernumber" align="center"></el-table-column>
				<el-table-column label="联系人" prop="contacts" align="center"></el-table-column>
				<el-table-column label="联系电话" prop="phone" align="center"></el-table-column>
				<el-table-column label="收货地址" prop="address" align="center"></el-table-column>
				<el-table-column label="支付金额" align="center" width="120">
					<template slot-scope="scope">
						<p v-if="scope.row.delivery_type == 2">运费：{{ scope.row.delivery_fee }}</p>
						<p v-if="scope.row.delivery_type == 1" style="color:blue">自提</p>
						<p>商品：{{ scope.row.total_amount }}</p>
						<p style="color:#1B9D6E">实付：{{ scope.row.amount }}</p>
					</template>
				</el-table-column>
				<el-table-column label="状态" align="center" width="70">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 1">已支付</span>
						<span v-if="scope.row.status == 2">已到站</span>
						<span v-if="scope.row.status == 3">已签收</span>
						<span v-if="scope.row.status == 4">退款中</span>
						<span v-if="scope.row.status == 5" style="color:red">已退款</span>
						<span v-if="scope.row.status == 6">订单关闭</span>
					</template>
				</el-table-column>
				<el-table-column label="信用付" align="center" width="70">
					<template slot-scope="scope">
						<span v-if="scope.row.order_type == 1">否</span>
						<span v-if="scope.row.order_type == 2">是</span>
					</template>
				</el-table-column>
				<el-table-column label="支付方式" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">线上微信</span>
						<span v-if="scope.row.type == 2">线上余额</span>
						<span v-if="scope.row.type == 30">线下现金</span>
						<span v-if="scope.row.type == 31">线下转账</span>
						<span v-if="scope.row.type == 32">线下扫码</span>
						<span v-if="scope.row.type == 39" style="color:#F56C6C;font-weight: 600;">线下其他</span>
					</template>
				</el-table-column>
				<el-table-column label="团长姓名" prop="leader_name" align="center"></el-table-column>
				<el-table-column label="团长电话" prop="leader_phone" align="center"></el-table-column>
				<el-table-column label="订单时间" prop="pay_time" align="center"></el-table-column>
				<el-table-column label="备注" prop="remarks" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="220">
					<template slot-scope="scope">
						<el-button type="success" size="mini" @click="handlePrint(scope.row)"
							v-if="scope.row.refund == 2">打印</el-button>
						<el-button type="warning" size="mini" @click="changeOrder(scope.row)"
							v-if="scope.row.type >= 30 && scope.row.type <= 39 && scope.row.status == 3">改单</el-button>
						<el-button type="danger" size="mini" @click="handleCancel(scope.row)"
							v-if="scope.row.type >= 30 && scope.row.type <= 39 && scope.row.status == 3">取消</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="page" @size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" :current-page="page" :page-sizes="pageSizes"
				layout="total, sizes, prev, pager, next, jumper" :page-size="pageNum" :total="total">
			</el-pagination>
		</div>
		<!-- 售后 -->
		<el-dialog title="主动售后" :visible.sync="afterSalesDialog" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="afterSalesForm" label-width="100px" style="padding-right: 30px;">
				<el-form-item label="处理方式">
					<el-select v-model="afterSalesForm.status" placeholder="请选择处理方式" style="width: 100%;">
						<el-option v-for="item in methodList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="金额" v-if="afterSalesForm.status == 2">
					<el-input v-model="afterSalesForm.total" placeholder="请输入退款金额"></el-input>
				</el-form-item>
				<el-form-item label="退款途径" v-if="afterSalesForm.status == 2">
					<el-select v-model="afterSalesForm.refund_type" placeholder="请选择退款途径" style="width: 100%;">
						<el-option v-for="item in refundTypeList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="售后原因">
					<el-input v-model="afterSalesForm.reason" placeholder="请输入售后原因"></el-input>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="afterSalesForm.remark" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="afterSalesDialog = false">取 消</el-button>
				<el-button type="warning" @click="afterSalesConfirmFn">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 打印设置 -->
		<!-- <el-dialog title="打印设置" :visible.sync="printDialog" :close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="form" :model="afterSalesForm" label-width="100px" style="padding-right: 30px;">
				<el-form-item label="处理方式">
					<el-select v-model="afterSalesForm.status" placeholder="请选择处理方式" style="width: 100%;">
						<el-option v-for="item in methodList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="金额" v-if="afterSalesForm.status == 2">
					<el-input v-model="afterSalesForm.total" placeholder="请输入退款金额"></el-input>
				</el-form-item>
				<el-form-item label="退款途径" v-if="afterSalesForm.status == 2">
					<el-select v-model="afterSalesForm.refund_type" placeholder="请选择退款途径" style="width: 100%;">
						<el-option v-for="item in refundTypeList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="售后原因">
					<el-input v-model="afterSalesForm.reason" placeholder="请输入售后原因"></el-input>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="afterSalesForm.remark" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="printDialog = false">取 消</el-button>
				<el-button type="warning" @click="afterSalesConfirmFn">确 定</el-button>
			</span>
		</el-dialog> -->
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				currentDateTime: this.getCurrentDateTime(),
				// 仓库
				warehouseOptions: [],
				// // 订单类型
				// orderTypeOptions: [
				//     {
				//         id: 1,
				//         name: '普通'
				//     },
				//     {
				//         id: 2,
				//         name: '信用付'
				//     }
				// ],
				// 打印设置
				printDialog: false,
				pageType: 1,
				pageTypeItem: {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				},
				pageTypeList: [{
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				}, {
					id: 2,
					name: '针式二等分多联纸',
					width: 2410,
					height: 1400
				}, {
					id: 3,
					name: '针式一等分多联纸',
					width: 2410,
					height: 2800
				}, {
					id: 4,
					name: '标准A4纸',
					width: 2100,
					height: 2970
				}],
				statusOptions: [{
						id: 0,
						name: '全部'
					},
					{
						id: 1,
						name: '待核销'
					},
					{
						id: 2,
						name: '已送达'
					},
					{
						id: 3,
						name: '部分送达'
					},
					{
						id: 4,
						name: '已退款'
					},
					{
						id: 5,
						name: '部分退款'
					}
				],
				// 提货方式
				deliveryTypeList: [{
						id: 1,
						name: '自提'
					},
					{
						id: 2,
						name: '配送'
					}
				],
				// 支付方式
				payTypeList: [{
						id: 1,
						name: '线上微信'
					},
					{
						id: 2,
						name: '线上余额'
					},
					{
						id: 30,
						name: '线下现金'
					},
					{
						id: 31,
						name: '线下转账'
					},
					{
						id: 32,
						name: '线下扫码'
					},
					{
						id: 39,
						name: '线下其他'
					}
				],
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				pageSizes: [10, 50, 200, 1000],
				// 供应商信息
				supplier_info: {},
				// 列表选择
				selectedData: [],
				exportDisabled: false, //批量打印
				writeOffDisabled: false, //批量核销
				//售后
				afterSalesDialog: false,
				afterSalesItem: {},
				afterSalesForm: {},
				methodList: [{
						id: 1,
						name: '补发'
					},
					{
						id: 2,
						name: '退款'
					},
					{
						id: 8,
						name: '其他'
					},
					{
						id: 9,
						name: '驳回'
					},
				],
				refundTypeList: [{
						id: 1,
						name: '原路退回'
					},
					{
						id: 2,
						name: '线下处理'
					},
				],

			}
		},
		mounted() {
			this.supplier_info = JSON.parse(sessionStorage.getItem('supplier_info'))
			// 仓库
			this.getWarehouseList()
			// 时间
			this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()

			this.pageType = localStorage.getItem("defaultPageType") ?? '1';
			this.pageType = Number(this.pageType)
			if (localStorage.getItem("defaultPageTypeItem")) {
				this.pageTypeItem = JSON.parse(localStorage.getItem("defaultPageTypeItem"));
			} else {
				this.pageTypeItem = {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				};
			}

		},
		watch: {
			'selectedData': {
				deep: true,
				handler(newVal, oldVal) {
					// 打印
					const exportArr = newVal.every(i => {
						return i.refund !== 1
					})
					if (exportArr) {
						this.exportDisabled = false
					} else {
						this.exportDisabled = true
					}
					// 批量核销
					const writeOffArr = newVal.every(item =>
						item.order_goods.every(child => {
							return child.status === 1 && item.order_type !== 2
						})
					);
					if (writeOffArr) {
						this.writeOffDisabled = false
					} else {
						this.writeOffDisabled = true
					}
				}
			}
		},
		methods: {
			selectPageType(value) {
				let that = this
				this.pageTypeList.forEach(item => {
					if (item.id == value) {
						that.pageTypeItem = item
					}
				})
				localStorage.setItem("defaultPageType", value);
				localStorage.setItem("defaultPageTypeItem", JSON.stringify(that.pageTypeItem));
			},
			getList() {
				this.$http.post(`erp/v1/order/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.tableData.forEach(item => {
							this.$set(item, 'checked', false)
							this.$set(item, 'indeterminate', false)
						})
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 父表全选
			handleParentSelectAll(selection) {
				for (const item of this.tableData) {
					item.checked = selection.length == 0 ? false : true;
					// 改变子表的勾选状态
					this.$nextTick(() => {
						let childTableRef = this.$refs['childTable' + item.order_id];
						// 子表是否存在
						if (childTableRef) {
							// 切换子表勾选状态
							childTableRef.toggleAllSelection();
						}
					});
				}
			},
			// 父表勾选
			handleParentSelect(selection, row) {
				this.$nextTick(() => {
					let childTableRef = this.$refs['childTable' + row.order_id];
					// 子表是否存在
					if (childTableRef) {
						// 切换子表勾选状态
						childTableRef.toggleAllSelection();
					}
				});
			},
			// 父表checkbox勾选
			toggleParentSelection(row, value) {
				if (value) {
					this.$refs.parentTable.toggleRowSelection(row, true);
				} else {
					this.$refs.parentTable.toggleRowSelection(row, false);
				}
				this.$nextTick(() => {
					let childTableRef = this.$refs['childTable' + row.order_id];
					// 子表是否存在
					if (childTableRef) {
						// 切换子表勾选状态
						childTableRef.toggleAllSelection();
					}
				});
			},
			// 父表勾选变化
			handleParentSelectionChange(selection) {
				this.selectedData = JSON.parse(JSON.stringify(selection));
			},
			// 父表展开变化
			handleParentExpandChange(row, expanded) {
				if (expanded) {
					let findIndex = this.selectedData.findIndex(item => item.order_id === row.order_id);
					if (findIndex > -1) {
						this.$nextTick(() => {
							let childTableRef = this.$refs['childTable' + row.order_id];
							if (childTableRef) {
								childTableRef.toggleAllSelection();
							}
						});
					}
				}
			},
			// 子表勾选变化
			handleChildSelectionChange(selection, scopeRow, rowItem) {
				// 将对应的order_goods勾选数据替换
				let findIndex = this.selectedData.findIndex(item => item.order_id === scopeRow.order_id);
				// 如果勾选为空，则取消父表勾选
				if (selection.length === 0) {
					this.$refs.parentTable.toggleRowSelection(scopeRow, false);
					scopeRow.checked = false;
					scopeRow.indeterminate = false;
				} else if (selection.length === scopeRow.order_goods.length) {
					// 如果全选，则勾选父表
					this.$refs.parentTable.toggleRowSelection(scopeRow, true);
					scopeRow.checked = true;
					scopeRow.indeterminate = false;
					if (findIndex > -1) {
						this.selectedData[findIndex].order_goods = JSON.parse(JSON.stringify(selection));
					}
				} else {
					// 如果半选，将父表状态改为不确定indeterminate
					scopeRow.checked = true;
					scopeRow.indeterminate = true;
					// 将对应的order_goods勾选数据替换
					if (findIndex > -1) {
						this.selectedData[findIndex].order_goods = JSON.parse(JSON.stringify(selection));
					} else {
						this.selectedData.push({
							...scopeRow,
							order_goods: JSON.parse(JSON.stringify(selection))
						})
					}
				}
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			padNumber(num) {
				return num < 10 ? '0' + num : num;
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 打印
			handlePrint(item) {
				if (item.order_id) {
					this.selectedData = []
					this.selectedData.push(item)
				} else {
					if (this.selectedData.length <= 0) {
						this.$message.error('请选择导出数据');
						return
					}
				}
				// 通知打印
				let orderArr = []
				this.selectedData.forEach(item => {
					orderArr.push(item.order_id)
				});
				this.$http.post(`/erp/v1/order/print`, {
					order_id: item.order_id ? item.order_id.toString() : orderArr.join(',')
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						// 打印
						setTimeout(() => {
							var LODOP = getLodop();
							var totalPrint = 0
							for (var i = 0; i < this.selectedData.length; i++) {
								totalPrint++
								var parentDiv = document.getElementById('export-template' + i);
								var strStyle =
									"<style> table,td,th {border-width: 1px;border-style: solid;border-collapse: collapse}</style>"
								// LODOP.ADD_PRINT_TABLE(100, "5%", "90%", this.pageType, strStyle + parentDiv
								// 	.querySelector("#template-table").innerHTML);

								//LODOP.SET_PRINTER_INDEX("Microsoft XPS Document Writer");
								LODOP.SET_PRINT_PAGESIZE(0, this.pageTypeItem.width, this.pageTypeItem
									.height, this.pageTypeItem.name);
								//LODOP.SET_PRINT_MODE("POS_BASEON_PAPER", true);

								LODOP.ADD_PRINT_TABLE(100, "5%", "90%", "100%",
									strStyle + parentDiv
									.querySelector("#template-table").innerHTML);

								LODOP.ADD_PRINT_HTM(2, "5%", "90%", 120, parentDiv
									.querySelector(
										"#template-title").innerHTML);
								/* LODOP.ADD_PRINT_TABLE(100, 25, this.pageTypeItem.width / 2.5, "100%",
									strStyle + parentDiv
									.querySelector("#template-table").innerHTML);

								LODOP.ADD_PRINT_HTM(2, 25, this.pageTypeItem.width / 2.5, 120, parentDiv
									.querySelector(
										"#template-title").innerHTML); */
								LODOP.SET_PRINT_STYLEA(0, "ItemType", 1);
								LODOP.SET_PRINT_STYLEA(0, "LinkedItem", -1);
								LODOP.NewPageA();
								if (totalPrint == this.selectedData.length) {
									LODOP.PREVIEW()
								}
							}
							this.getList()
							this.selectedData = []
						}, 500);
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 导出
			handleExport() {
				this.$http.post(`erp/v1/order/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm,
					export: 1
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 改单
			changeOrder(item) {
				this.$router.push({
					path: '/salesBilling',
					query: {
						order_id: item.order_id
					}
				});
				// 存储路由
				sessionStorage.setItem('activeMenu', 'salesBilling');
				setTimeout(() => {
					window.location.reload();
				}, 1000)
			},
			// 手动开单取消
			handleCancel(item) {
				this.$confirm(`确定取消该订单吗, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/order/cancel`, {
						order_id: item.order_id
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			// 主动售后
			afterSalesConfirmFn() {
				this.$confirm(`确定主动售后该条数据吗, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/order/force_service_do`, {
						order_goods_id: this.afterSalesItem.goods_order_id,
						...this.afterSalesForm
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
							this.afterSalesDialog = false
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			// 退款
			refundFn(id, item) {
				this.$confirm(`确定退款${item.amount}元吗, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`erp/v1/order/refund`, {
						order_id: id,
						goods_order_ids: item.goods_order_id,
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			// 核销
			writeOff(item) {
				let orderArr = []
				if (!item.goods_order_id) {
					if (this.selectedData.length <= 0) {
						this.$message.error('请选择核销数据');
						return
					}
					this.selectedData.forEach(i => {
						i.order_goods.forEach(j => {
							orderArr.push(j.goods_order_id)
						})
					});
				}
				this.$confirm(`确定核销该商品吗, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`erp/v1/order/check`, {
						goods_order_ids: item.goods_order_id ? item.goods_order_id.toString() : orderArr
							.join(','),
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.page {
		margin-top: 20px;
		text-align: right;
	}
</style>